import React from "react";
import arrows from '../../assets/img/arrows.svg';
import "./LoaderButton.css";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  customStyles,
  ...props
}) => {

  const styles = {
    backgroundColor: "white",
    color: "#1e3553",
    ...customStyles
  }

  return (
    <div style={{ ...styles }} className={`LoaderButton ${className}`} disabled={disabled || isLoading} {...props}>
      {isLoading && <img alt="spinner" src={arrows} className="spinning" />}
      {!isLoading ? text : loadingText}
    </div>
  )
}