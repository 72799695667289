import React, { Component } from 'react';
import LoadingIndicatorOverlay from "./LoadingIndicatorOverlay/LoadingIndicatorOverlay";


export default class Workspace extends Component {
    render() {
        if (this.props.timeoutHandler)
        {
            this.props.timeoutHandler();
        }

        return (
            <div className="workspace">
                {this.props.pageHeader}
                {this.props.sidebar}

                <div className={`workspace__content ${!this.props.toolbarHidden ? "toolbar-is-open" : ""} ${this.props.isMenuOpen ? "menu-is-open" : ""}`}>
                    { this.props.isLoadingResources ? <LoadingIndicatorOverlay />  : <div></div> }
                    {this.props.content}

                </div>

            </div >
        )
    }
}