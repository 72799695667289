import React, { Component } from "react";

import { authContext } from './libs/adal'
import "./App.css";
import LoginContainer from './components/pages/login/LoginContainer'
import WorkspaceContainer from './components/pages/workspace/WorkspaceContainer';
import ReactAI from 'react-appinsights';

class App extends Component { 
    constructor(props) {
        super(props); 
        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            activeLanguage: 0,
            ai: ReactAI.ai(),
            user: {},
            error: null,
            toolbarHidden: false
        };
    }

    componentDidMount() {
        if (localStorage.getItem('activeLanguage') === null) {
            const browserLanguage = navigator.language ? navigator.language.substr(0, 2) : navigator.userLanguage.substr(0, 2)
            switch (browserLanguage) {
                case "de":
                    localStorage.setItem('activeLanguage', 2);
                    break;
                case "it":
                    localStorage.setItem('activeLanguage', 1);
                    break;
                default:
                    localStorage.setItem('activeLanguage', 0);
            }
        }
        if (+localStorage.getItem('activeLanguage')) this.setState({ activeLanguage: localStorage.activeLanguage });
        if (authContext.getCachedToken(authContext.config.clientId)) {
            const sid = authContext.getCachedUser().profile.sid;
            this.state.ai.setAuthenticatedUserContext(sid,null,true)
            this.setState({ isAuthenticated: true, isAuthenticating: false, user: authContext.getCachedUser() });
        }
    }

    onToggleToolbarVisibility = () => {
        this.setState({ toolbarHidden: !this.state.toolbarHidden });
    }

    onChangeLanguage = chosenLanguage => {
        if (chosenLanguage !== this.state.activeLanguage) {
            localStorage.setItem('activeLanguage', chosenLanguage);
            this.setState({ activeLanguage: chosenLanguage });
        }
    }


    render() {
        const props = { 
            toolbarHidden: this.state.toolbarHidden,
            toggleToolbarVisibility: this.onToggleToolbarVisibility,
            isAuthenticated: this.state.isAuthenticated,
            isAuthenticating: this.state.isAuthenticating,
            error: this.state.error,
            isOpenLangPicker: this.state.isOpenLangPicker,
            activeLanguage: this.state.activeLanguage,
            onChangeLanguage: this.onChangeLanguage,
            ai: this.state.ai,
            user: this.state.user,
        }

        return !this.state.isAuthenticated
            ? <LoginContainer {...props} />
            : <WorkspaceContainer {...props} key={this.state.toolbarHidden} />
    }
}

export default App;