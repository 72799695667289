import React, { Component } from 'react';
import Modal from 'react-modal';
import LoaderButton from '../../../shared/LoaderButton'
import translations from '../../../../translations.json';
import './Eula.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '50%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: "100",
        padding: "80px",
        textAlign: "justify",
        height: "80%",
        width: "50%",
        overflowY: "scroll",
        boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)"

    },
    overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(50, 50, 50, 0.75)'
    }
};

export default class Eula extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Modal
                isOpen={!this.props.hasUserConsentedToEula}
                style={customStyles}
                ariaHideApp={false}
                contentLabel={translations[this.props.activeLanguage].values['enduseragreement_title']}
            >
                <h2>{translations[this.props.activeLanguage].values['enduseragreement_title']}</h2>
                <div style={{marginBottom:"20px"}}>
                    <ol style={{ listStyleType: "decimal" }}>
                        <li>{translations[this.props.activeLanguage].values['enduseragreement_li1']}
                        <ol style={{ listStyleType: "lower-alpha", paddingLeft: "50px" }}>
                                <li>{translations[this.props.activeLanguage].values['enduseragreement_ol_li1_text1']}<span style={{fontWeight:"600"}}>{translations[this.props.activeLanguage].values['enduseragreement_ol_li1_highlight1']}</span>{translations[this.props.activeLanguage].values['enduseragreement_ol_li1_text2']}<span style={{fontWeight:"600"}}>{translations[this.props.activeLanguage].values['enduseragreement_ol_li1_highlight2']}</span>{translations[this.props.activeLanguage].values['enduseragreement_ol_li1_text3']}</li>
                                <li>{translations[this.props.activeLanguage].values['enduseragreement_ol_li2']}</li>
                            </ol>
                        </li>
                        <li>{translations[this.props.activeLanguage].values['enduseragreement_li2']}</li>
                        <li>
                        {translations[this.props.activeLanguage].values['enduseragreement_li3_p1']}
                        <br/><br/>
                        {translations[this.props.activeLanguage].values['enduseragreement_li3_p2']}
                        </li>
                        <li>{translations[this.props.activeLanguage].values['enduseragreement_li4']}</li>
                        <li>{translations[this.props.activeLanguage].values['enduseragreement_li5']}</li>
                    </ol>
                </div>
                <LoaderButton
                    onClick={() => this.props.consentToEula()}
                    isLoading={this.props.isLoadingEulaConsent}
                    text={translations[this.props.activeLanguage].values['agree']}
                    loadingText={"Loading..."}
                    customStyles={{backgroundColor: "#235a99", color: "white"}}
                />
            </Modal>
        )
    }
}