import config from '../config'

function findDashboardSize() {
    var minHeight = 480;
	var minWidth  = 300;
    var width;
    var innerWidth = window.innerWidth;
	
	// The minus px here leaves us room for the header and menu. Adjust as needed.
	var height = window.innerHeight - 65;
    if (innerWidth > 1024){
        width = innerWidth - 240;
    } else {
        width = innerWidth;
    }

	if (height < minHeight) {
		height = minHeight;
	}

    if (width < minWidth) {
		width = minWidth;
	}
	
	return {
		'width'  : width,
		'height' : height
	}
}

function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className+ ' ') > -1;
}

function resize() {
    var workspaceContent =  document.querySelector('.workspace__content-inside');
    var vizIframe = document.querySelector( '.workspace__content-inside iframe' );
    if (workspaceContent !== null && vizIframe !== null) {
        var size = findDashboardSize();
        var isToolbarOpen = hasClass( workspaceContent, "toolbar-is-open" );
        if ( isToolbarOpen ) {
            size.height += 22;
        }

        document.querySelector( '.workspace__content-inside' ).style.width = size.width + "px";
        document.querySelector( '.workspace__content-inside' ).style.height = size.height + "px";
        vizIframe.style.width = size.width + "px";
        vizIframe.style.height = size.height + "px";
    }
}

window.onresize = resize;

const Viz = (function () {

    var _instance;
    var _viz;
    var size = findDashboardSize();
    var _options = {
        hideTabs: true,
        hideToolbar: false,
        width: size.width + "px",
        height: size.height + "px",
        toolbarPosition: "tableauSoftware.ToolbarPosition.TOP",
    };

    const createViz = async (dom, pth, contentUrl, trustedTicket, callback, events, hideToolbar) => {
        return new Promise(function (resolve, reject) {
            if (dom && pth && trustedTicket) {
                _viz && _viz.dispose && _viz.dispose();
                var path = `${config.APP_CONFIG.tableauHost}/trusted/${trustedTicket}${contentUrl === "" ? "" : "/t/" + contentUrl}/${pth}`
                if (hideToolbar !== null)
                {
                    _options.hideToolbar = hideToolbar;
                }
                _viz = new window.tableau.Viz(dom, path, {..._options, onFirstInteractive: callback});
                if(_viz) {
                    if (events != null && events.length)
                    {
                        for(var i=0;i<events.length;i++)
                        {
                            _viz.removeEventListener(events[i].type, events[i].handler);
                            _viz.addEventListener(events[i].type, events[i].handler);
                        }
                    }
                    // if the window is maximized and toolbar is being toggled
                    resize();
                    resolve(_viz)
                } else {
                    reject("Viz could not be created")
                }
               
            } else {
                reject("Parameters missng to init viz")
            }

        })
    }

    const Viz = () => {
        if (!_instance) {    
            _instance = this;
        }
        return _instance;
    }

    Viz.prototype = {
        getViz: () => _viz,
        createViz: (domEl, path, contentUrl, trustedTicket, callback, events, hideToolbar) => createViz(domEl, path, contentUrl, trustedTicket, callback, events, hideToolbar),
        exportToPDF: () => _viz.showExportPDFDialog(),
        exportToJSON: () => _viz.showExportDataDialog(),
        exportToImage: () => _viz.showExportImageDialog(),
        exportAsCrosstab: () => _viz.showExportCrossTabDialog(),
        revertAllAsync: () => _viz.revertAllAsync(),
        refreshDataAsync: () => _viz.refreshDataAsync(),    
        addEventListener: (eventType, listener) => _viz.addEventListener(eventType, listener),
        removeEventListener: (eventType, listener) => _viz.removeEventListener(eventType, listener)
    }

    return Viz;
}())

export default Viz;