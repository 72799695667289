import React, { Component } from 'react';
import Modal from 'react-modal';
import './Impersonation.css';
import translations from '../../../../translations.json';
import { invokeApi } from '../../../../libs/adal'
import {Button} from "reactstrap";

const customStyles = {
	content: {
		display: 'flex',
		flexDirection: 'column',
		top: '50%',
		left: '50%',
		right: '50%',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		zIndex: "100",
		padding: "50px",
		textAlign: "justify",
		height: "80%",
		width: "50%",
		boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)"
	},
	overlay: {
		zIndex: 100,
		backgroundColor: 'rgba(50, 50, 50, 0.75)'
	}
};

export default class Impersonation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			usernameToImpersonate: "",
			allUsers: [],
			filteredUsers: [],
			usernameFilter: "",
			loading: true
		}
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.impersonationModalOpen && this.props.impersonationModalOpen) {
			this.fetchUsers();
		}
	}

	filterUsers = (allUsers, search) => {
		if (!allUsers || !allUsers.length){
			return [];
		}

		const s = search.trim().toLowerCase();

		if (!s){
			return allUsers;
		}

		return allUsers.filter((el) => el.name.toLowerCase().indexOf(s) >= 0
										|| el.email.toLowerCase().indexOf(s) >= 0
										|| el.samAccountName.toLowerCase().indexOf(s) >= 0);
	};

	fetchUsers = () => {
		invokeApi({ path: "/admin/tableauusers" })
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("Unable to get users");
				}
			})
			.then((tableauUsers) => {
				this.setState({
					allUsers: tableauUsers,
					filteredUsers: this.filterUsers(tableauUsers, this.state.usernameFilter)
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				})
			})
	};

	onRowSelected = (samAccountName) => {
		if (samAccountName)
		{
			this.props.confirmImpersonation(samAccountName);
		}
	};

	usernameFilterOnChange = (e) => {
		this.setState({
			usernameFilter: e.target.value,
			filteredUsers: this.filterUsers(this.state.allUsers, e.target.value)
		});
	};

	modalOnClose = () => {
		this.props.confirmImpersonation(this.props.currentImpersonation);
	};

	stopImpersonation = () => {
		this.props.confirmImpersonation("");
	};

	render() {
		const userRows = [];
		const users = this.state.filteredUsers || [];

		for (let i=0;i < users.length;i++)
		{
			const user = users[i];
			userRows.push(
				<tr onClick={() => this.onRowSelected(user.samAccountName)} className={"datarow"} key={users[i].samAccountName}>
					<td>{users[i].name}</td>
					<td>{users[i].email}</td>
				</tr>
			);
		}

		return (
			<Modal
				animation={false}
				isOpen={this.props.impersonationModalOpen}
				style={customStyles}
				ariaHideApp={false}
				shouldCloseOnEsc={true}
				shouldCloseOnOverlayClick={true}
				onRequestClose={this.modalOnClose}
				contentLabel={translations[this.props.activeLanguage].values['enduseragreement_title']}
			>
				<div style={{textAlign: "center"}}>
					<h2>Impersonate user</h2>
				</div>

				{this.props.currentImpersonation && (
					<div className="impersonate-warning">
						<span>Currently impersonating <strong>{this.props.currentImpersonation}</strong></span>
						<Button color="danger" onClick={this.stopImpersonation}>Stop impersonating</Button>
					</div>
				)}

				<div style={{marginTop: "15px"}}>
					<span style={{marginRight: "20px"}}>Filter</span>
					<input id={"filteruser"} type="text" value={this.state.usernameFilter} onChange={this.usernameFilterOnChange} />
				</div>

				{this.state.loading ? (
					<div className="spinner-wrapper">
						<div className="spinner"></div>
					</div>
				) : (
					<div id={"wrapperdiv"}>
						<table id={"tabusers"}>
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
								</tr>
							</thead>
							<tbody>
								{userRows}
							</tbody>
						</table>
					</div>)
				}
			</Modal>
		)
	}
}