import { inject } from 'adal-angular/dist/adal.min.js'
import config from '../config'

const adalConfig = {
  tenant: config.ADFS_CONFIG.tenant,
  clientId: config.ADFS_CONFIG.clientID,
  cacheLocation: 'localStorage',
  instance: config.ADFS_CONFIG.authority,
  redirectUri: config.ADFS_CONFIG.redirectUri,
  extraQueryParameter: `scope=openid%20email%20profile%20${config.ADFS_CONFIG.clientID}&response_mode=form_post`
};

export const authContext = inject(adalConfig);

export async function invokeApi({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  body
}) {
  if (!authContext.getCachedToken(authContext.config.clientId)) {
    logOut();
  }
  return fetch(`${config.APP_CONFIG.apiPath}${path}`, { method, headers: { "Authorization": "Bearer " + authContext.getCachedToken(authContext.config.clientId), "Content-Type": "application/json" }, body })
}

export async function logOut() {
  const id_token_hint = authContext.getCachedToken(authContext.config.clientId);
  authContext.clearCache();
  window.location.href = `${config.ADFS_CONFIG.authority}${config.ADFS_CONFIG.tenant}/oauth2/logout?post_logout_redirect_uri=${encodeURIComponent(config.ADFS_CONFIG.postLogoutRedirectUri)}${id_token_hint ? "&id_token_hint=" + id_token_hint : ""}`
}


export function getTokenClaim(key)
{
    const token = authContext.getCachedToken(authContext.config.clientId);

	const tokenParts = token.split('.');
	if (tokenParts.length < 3) {
		return null;
	}

	const payload = tokenParts[1];
	const decodedPayload = JSON.parse(window.atob(payload));

	return decodedPayload[key];
}