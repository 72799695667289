import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ApiRedirect = () => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		// Only forward GET requests to the API
		const forwardGetRequest = async () => {
			try {
				// Construct the API URL with current pathname and search parameters (query string)
				const apiUrl = `/api${location.pathname.replace(/^\/api/, '')}${location.search}`;

				// Forward the GET request to the backend API
				const response = await fetch(apiUrl, {
					method: 'GET',
					credentials: 'include' // include cookies if needed
				});

				// Check if there's a redirect response
				if (response.redirected) {
					// Redirect to the new URL provided by the API
					navigate(response.url.replace(window.location.origin, ''));
				} else if (!response.ok) {
					// Handle error response (e.g., show an error page or log)
					console.error('API GET request failed:', response.statusText);
					navigate(''); // Navigate to a custom error route
				}
			} catch (error) {
				console.error('Failed to forward GET request:', error);
				navigate(''); // Handle network errors or exceptions
			}
		};

		// Trigger only if the request is a GET request (since we are in React, assume GET request for /api path)
		forwardGetRequest();
	}, [location, navigate]);

	return null; // Render nothing
};

export default ApiRedirect;