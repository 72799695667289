import React, { Component, Fragment } from 'react';
import Lottie from 'lottie-react'
import BrokenStick from '../../../../assets/lottie/broken_stick_error.json'
import translations from '../../../../translations.json'



export default class Error extends Component {



    render() {

        return (
            <Fragment>
            <Lottie style={{ height: '300px'}} animationData={BrokenStick} />
            <div style={{textAlign: 'center'}}>{translations[this.props.activeLanguage].values[this.props.errorType]}</div>
            </Fragment>

        )
    }
}