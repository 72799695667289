import React, { Component } from 'react';
import { authContext } from '../../../libs/adal'

import Login from './Login'

export default class LoginContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {
        this.props.ai.trackPageView("login");
    }

    login = () => {
        this.setState({
          isLoading: true
        });
        authContext.login();
    }

    render() {

    const loginProps = {
        login: this.login,
        isLoading: this.state.isLoading,
        onChangeLanguage: this.props.onChangeLanguage,
        activeLanguage: this.props.activeLanguage
    }
        return (
           <Login {...loginProps}/>
        )
    }
}