const dev = {
  ADFS_CONFIG: {
    clientID: 'b3447305-1128-4b33-b15e-9ec78e33a5ac',
    authority: "https://login.dataforce.de/",
    tenant: "adfs",
    redirectUri: "https://iris-views-dev.dataforce.de/api/auth",
    postLogoutRedirectUri: "https://iris-views-dev.dataforce.de",
  },
  APP_CONFIG: {
    apiPath: "https://iris-views-dev.dataforce.de/api",
    tableauHost: process.env.REACT_APP_TABLEAU_HOST,
    gaTrackingID: "UA-00000000-00",
    gaTestMode: true,
    aiInstrumentationKey: '045c4a8a-235d-4ba0-ac39-949b8c1a0c46'
  }
};

const test = {
  ADFS_CONFIG: {
    clientID: '00d1af8d-ad5f-4903-b1f2-097d2b4de38d',
    authority: "https://login-test.dataforce.de/",
    tenant: "adfs",
    redirectUri: "https://iris-views-test.dataforce.de/api/auth",
    postLogoutRedirectUri: "https://iris-views-test.dataforce.de",
  },
  APP_CONFIG: {
    apiPath: "/api",
    tableauHost: process.env.REACT_APP_TABLEAU_HOST,
    gaTrackingID: "UA-00000000-00", 
    gaTestMode: true,
    aiInstrumentationKey: '490907e0-19d2-48fb-86bf-43a9fcb56ef0'
  }
};

const stg = {
  ADFS_CONFIG: {
    clientID: 'adf8b58d-6cc3-42e4-8f13-4ce2d9db6fdf',
    authority: "https://login.dataforce.de/",
    tenant: "adfs",
    redirectUri: "https://iris-views-stg.dataforce.de/api/auth",
    postLogoutRedirectUri: "https://iris-views-stg.dataforce.de"
  },
  APP_CONFIG: {
    apiPath: "/api",
    tableauHost: process.env.REACT_APP_TABLEAU_HOST,
    gaTrackingID: "UA-00000000-00",
    gaTestMode: true,
    aiInstrumentationKey: '9dc9116c-58a6-4304-9f5d-2ba1d9e92798'
  }
};

const prod = {
  ADFS_CONFIG: {
    clientID: 'adf8b58d-6cc3-42e4-8f13-4ce2d9db6fdf',
    authority: "https://login.dataforce.de/",
    tenant: "adfs",
    redirectUri: "https://iris-views.dataforce.de/api/auth",
    postLogoutRedirectUri: "https://iris-views.dataforce.de"
  },
  APP_CONFIG: {
    apiPath: "/api",
    tableauHost: process.env.REACT_APP_TABLEAU_HOST,
    gaTrackingID: "UA-48082753-25",
    gaTestMode: false,
    aiInstrumentationKey: '7edbb63c-0d40-46c2-bea4-ca4a5fc7286e'
  }
};

var config;

// Default to dev if not set
switch (process.env.REACT_APP_STAGE) {
  case 'prod':
    config = prod;
    break;
  case 'test':
    config = test;
    break;
  case 'stg':
    config = stg;
    break;
  case 'dev':
    config = dev;
    break;
  default:
    config = dev; 

}
export default {
  // Add common config values here
  ...config
};