import React, { Component } from "react";
import translations from '../../../translations.json';
import links from '../../../links.json';
import logo from '../../../assets/img/logo.svg';
import background from '../../../assets/img/main-big.jpg';
import '../login/Login.css';

class AccessDenied extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLanguage: 0,
            isOpenLangPicker: false,
            isOpenContactPicker: false
        };
    }

    componentDidMount() {
        if (localStorage.getItem('activeLanguage') === null) {
            const browserLanguage = navigator.language ? navigator.language.substr(0, 2) : navigator.userLanguage.substr(0, 2)
            switch (browserLanguage) {
                case "de":
                    localStorage.setItem('activeLanguage', 2);
                    break;
                case "it":
                    localStorage.setItem('activeLanguage', 1);
                    break;
                default:
                    localStorage.setItem('activeLanguage', 0);
            }
        }
        if (+localStorage.getItem('activeLanguage')) this.setState({ activeLanguage: localStorage.activeLanguage });
    }

    onChangeLanguage = chosenLanguage => {
        if (chosenLanguage !== this.state.activeLanguage) {
            localStorage.setItem('activeLanguage', chosenLanguage);
            this.setState({ activeLanguage: chosenLanguage });
        }
    }

    onClickLangPicker = (value) => {
        if ((value !== undefined) && this.state.isOpenLangPicker) this.setState({ isOpenLangPicker: !!value });
        this.setState(({ isOpenLangPicker }) => ({ isOpenLangPicker: !isOpenLangPicker }));
    }

    onClickContactPicker = (value) => {
        if ((value !== undefined) && this.state.isOpenContactPicker) this.setState({ isOpenContactPicker: !!value });
        this.setState(({ isOpenContactPicker }) => ({ isOpenContactPicker: !isOpenContactPicker }));
    }

    render() {
        const { activeLanguage, isLoading, login, isOpenLangPicker, isOpenContactPicker  } = this.state;
        const { onChangeLanguage, onClickLangPicker, onClickContactPicker } = this;

        return (
            <div className="login">
                <img className="login__background" src={background} alt="beautifull city"/>
                <div className="login__container">
                    <header className="login__header">
                        <div className="login__logo-wrapper">
                            <img className="login__logo-img" src={logo} alt="IRIS VIEWS"/>
                        </div>
                    </header>
                    <div className="access_denied__content">
                        <h2 className="login__greeting">
                            <div
                                className="login__greeting_bold">{translations[activeLanguage].values['accessDenied']}</div>
                            <div
                                className="login__greeting_small">{translations[activeLanguage].values['pleaseContact']}</div>
                        </h2>
                        <div
                            className="contact-large"
                            style={{top: '-20px'}}><a
                            href={'tel:' + translations[activeLanguage].values['contactPhone']}>{translations[activeLanguage].values['contactPhone']}</a>
                        </div>
                        <div
                            className="contact-large"
                            style={{top: '-10px'}}><a
                            href={'mailto:' + translations[activeLanguage].values['contactMail']}>{translations[activeLanguage].values['contactMail']}</a>
                        </div>
                    </div>
                    <footer className="login__footer">
                        <div className="login__footer-left-side">
                            <a href={links[activeLanguage].values['imprint']}
                               className="login__gray_item">{translations[activeLanguage].values['imprint']}</a>
                            <a href={links[activeLanguage].values['terms']}
                               className="login__gray_item">{translations[activeLanguage].values['terms']}</a>
                            <a href={links[activeLanguage].values['privacy']}
                               className="login__gray_item">{translations[activeLanguage].values['privacy']}</a>
                        </div>
                        <div className="login__footer-middle">
                            <div onClick={onClickContactPicker} className="login__cursor-pointer">
                            <div className="login__language-container">
                                    <div
                                        className="login__contact">{translations[activeLanguage].values['contact']}</div>

                                    <div
                                        className={isOpenContactPicker ? "login__language-other_active" : "login__language-other"}
                                    >
                                        <div
                                            className={isOpenContactPicker ? "login__language-other_active" : "login__language-other"}
                                            style={{top: '-20px'}}><a
                                            href={'tel:' + translations[activeLanguage].values['contactPhone']}>{translations[activeLanguage].values['contactPhone']}</a>
                                        </div>
                                        <div
                                            className={isOpenContactPicker ? "login__language-other_active" : "login__language-other"}
                                            style={{top: '-10px'}}><a
                                            href={'mailto:' + translations[activeLanguage].values['contactMail']}>{translations[activeLanguage].values['contactMail']}</a>
                                        </div>

                                    </div>

                                </div>
                                <div
                                    className={isOpenContactPicker ? "login__lang-picker-triangle_active" : "login__lang-picker-triangle"}></div>
                            </div>
                        </div>
                        <div className="login__footer-right-side">
                            <p className="login__gray_item">{translations[activeLanguage].values['language']}: </p>
                            <div onClick={onClickLangPicker} className="login__cursor-pointer">
                                <div className="login__language-container">
                                    <div onClick={() => onChangeLanguage(activeLanguage)}
                                         className="login__language">{translations[activeLanguage].name}</div>
                                    {
                                        translations.map(({name}) => name).reduce((accum, lang, idx) => idx !== activeLanguage ? [...accum, {
                                            lang,
                                            idx
                                        }] : accum, [])
                                            .map(({lang, idx}, index) => (
                                                <div
                                                    key={lang}
                                                    className={isOpenLangPicker ? "login__language-other_active" : "login__language-other"}
                                                    style={{top: `${isOpenLangPicker ? -10 * ++index : 0}px`}}
                                                    onClick={() => onChangeLanguage(idx)}
                                                >
                                                    {lang}
                                                </div>
                                            ))
                                    }
                                </div>
                                <div
                                    className={isOpenLangPicker ? "login__lang-picker-triangle_active" : "login__lang-picker-triangle"}></div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

export default AccessDenied;