import React, { Component, Fragment } from 'react';
import Lottie from 'lottie-react'
import Spinner from '../../../../assets/lottie/spinner.json'
import translations from '../../../../translations.json'



export default class LoadingIndicatorOverlay extends Component {



    render() {

        return (
            <Fragment>
                <div className="loadingindicatoroverlay">
                    <Lottie style={{ height: '150px'}} animationData={Spinner} />
                </div>
            </Fragment>

        )
    }
}